import {Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import {PrimaireService} from "../../services/primaire/primaire.service";
import {CollegeService} from "../../services/college/college.service";
import {LyceeService} from "../../services/lycee/lycee.service";
import {SearchQueryComponent} from "../commun/search-query/search-query.component";
import {ActivatedRoute, Router} from "@angular/router";
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { InplaceModule } from 'primeng/inplace';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-classement',
  templateUrl: './classement.component.html',
  styleUrls: ['./classement.component.css']
})
export class ClassementComponent implements OnInit {

  visible: boolean = false;
  position: string = 'center';
  rechercheValue: string = '';
  primaires: any[];
  url: any;
  primairesFromQuery: any[];
  progressBarValue: boolean = true;
  divCollege: boolean = false;
  divPrimaire: boolean = false;
  divLycee: boolean = false;
  previousUrl: string = "/etablissement";
  etablissements: any;
  etablissementsOriginels: any;
  title: string = "Classement 2023-2024 des meilleurs collèges";
  nomEcoles: string = "primaires";
  nomEcole: string = "primaire";
  listSize: number;
  @ViewChild
  (SearchQueryComponent, {static: true}) searchQueryComponent;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  isSmallScreen = false;
  isPortableScreen = false;
  isLaptopScreen = false;

  constructor(protected primaireService: PrimaireService,
              private collegeService: CollegeService,
              private lyceeService: LyceeService,
              private route: ActivatedRoute,
              private readonly router: Router,
              private changeDetectorRef: ChangeDetectorRef,
              private media: MediaMatcher) {
     this.url = this.router.url;
     console.log(" URL titi >>>>>>> ::: " + this.url);
     this.taillePourAfficher(changeDetectorRef, media);
  }

  @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.isSmallScreen = window.innerWidth < 768;
      this.isLaptopScreen = window.innerWidth < 400;
  }

  ngOnInit(): void {
    //this.loadPrimaires();
    console.log(" URL url >>>>>> ::: " + this.url);
    this.loadEtablissement();
    this.isSmallScreen = window.innerWidth < 768;
    this.isLaptopScreen = window.innerWidth < 400;
  }

  loadEtablissement() {
      switch (this.url) {
        case '/cPrimaire':
          console.log("It is a primaire.");
          this.loadPrimaires();
          break;
        case '/cCollege':
          console.log("It is a college.");
          this.loadColleges();
          break;
        case '/cLycee':
          console.log("It is a college.");
          this.loadLycees();
          break;
        default:
          console.log("Aucun établissement ne correspond à cet identifiant!");
          break;
      }
  }

  loadPrimaires() {
         this.divPrimaire = true;
         this.divCollege = false;
         this.divLycee = false;
    return this.primaireService.getClassementParAnnee().subscribe((data) => {
            this.etablissements = data;
            this.etablissementsOriginels = data;
            this.nomEcoles = 'primaires';
            this.nomEcole = 'primaire';
            this.listSize = this.etablissements.length;
            this.title = 'Classement 2023-2024 des meilleurs primaires';
          }, error => {
            console.error('There was an error!', error);
            console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++");
    });
  }

  loadColleges() {
      this.divCollege = true;
      this.divPrimaire  = false;
      this.divLycee = false;
      return this.collegeService.getClassementParAnnee().subscribe((data) => {
        this.etablissements = data;
        this.etablissementsOriginels = data;
        console.log('TOTO  ' + JSON.stringify(this.etablissements[0]?.statistiques?.[0]?.pourcentageReussite));
        this.nomEcoles = 'collèges';
        this.nomEcole = 'collège';
        this.listSize = this.etablissements.length;
        this.title = 'Classement 2023-2024 des meilleurs collèges';
      }, error => {
        console.error('There was an error!', error);
        console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++");
      });
  }

 loadLycees() {
       this.divCollege = false;
       this.divPrimaire = false;
       this.divLycee = true;
       return this.lyceeService.getClassementParAnnee().subscribe((data) => {
         this.etablissements = data;
         this.etablissementsOriginels = data;
         this.nomEcoles = 'lycées';
         this.nomEcole = 'lycée';
         this.listSize = this.etablissements.length;
         this.title = 'Classement 2023-2024 des meilleurs lycées';
       }, error => {
         console.error('There was an error!', error);
         console.error("++++++++++++++++++++++++++++++++++++++++++++++++++++");
       });
   }

    formatName(name: string): string {
       return name.split(' ').map(part => {
         return part.split('/').map(subPart => {
           return subPart.split('.').map((segment, index) => {
             if (index === 0) {
               // Garder la première lettre en majuscule
               return segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
             }
             // Les autres segments après le premier point en minuscule
             return segment.toLowerCase();
           }).join('.');
         }).join(' / ');
       }).join(' ');
    }

    formatStatut(statut: string): string {
        if (statut==='PUBLIC')  {
          return 'Public';
        } else if (statut==='PRIVE') {
          return 'Privé';
        }
    }

    showDialog(position: string) {
//          this.rechercheValue ='';
         this.position = position;
         this.visible = true;
    }

 pInputValue() {
    // Utilisez la valeur de `rechercheValue`
    if (this.rechercheValue !='') {
      let newLength = this.etablissementsOriginels.filter(etablissement => this.aplanirNom(etablissement.nom).includes(this.aplanirNom(this.rechercheValue))).length;
      console.log(' this.etablissements.length before '+this.etablissements.length + ' this.rechercheValue ' + this.rechercheValue + ' newLength '+newLength);
      if (newLength>0) {
          console.log("YOUPI SUPERIEUR");
         this.etablissements = this.etablissementsOriginels.filter(etablissement => this.aplanirNom(etablissement.nom).includes(this.aplanirNom(this.rechercheValue)));
         console.log('BABA  ' + JSON.stringify(this.etablissements));
      } else {
         this.loadEtablissement();
      }
       console.log("YOUPI RIEN");
       console.log(' this.etablissements.length after '+this.etablissements.length);
    } else {
      this.loadEtablissement();
    }
 }

 aplanirNom(value) {
     return value.toLowerCase()
                 .replace(/[\s\-]+/g, '')  // Retire les espaces et tirets
                 .replace(/[éèêë]/g,"e")   // Remplace les caractères accentués
                 .replace(/[àâä]/g,"a")
                 .replace(/[îï]/g,"i")
                 .replace(/[ôö]/g,"o")
                 .replace(/[ùûü]/g,"u")
                 .replace(/[ç]/g,"c");
 }

 ngOnDestroy(): void {
   this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
 }

  taillePourAfficher(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher): void {
        this.mobileQuery = media.matchMedia('(max-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

}
