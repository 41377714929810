import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { TreeModule } from 'primeng/tree';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrimaireComponent } from './pages/primaire/primaire.component';
import { CollegeComponent } from './pages/college/college.component';
import { LyceeComponent } from './pages/lycee/lycee.component';
import { SuperieurComponent } from './pages/superieur/superieur.component';
import { HomeComponent } from './pages/home/home.component';
import {HttpClientModule} from '@angular/common/http';
import {ButtonModule} from 'primeng/button';
import {MegaMenuModule} from 'primeng/megamenu';
import {InputTextModule} from 'primeng/inputtext';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import {FieldsetModule} from 'primeng/fieldset';
import {MenubarModule} from 'primeng/menubar';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {TableModule} from 'primeng/table';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DataViewModule} from 'primeng/dataview';
import { ProfessionnelComponent } from './pages/professionnel/professionnel.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SelectButtonModule} from "primeng/selectbutton";
import {MultiSelectModule} from "primeng/multiselect";
import {AutoCompleteModule} from "primeng/autocomplete";
import { SearchQueryComponent } from './pages/commun/search-query/search-query.component';
import { DetailEtablissementComponent } from './pages/commun/detail-etablissement/detail-etablissement.component';
import { DetailLocaliteComponent } from './pages/commun/detail-localite/detail-localite.component';
import { ListeEtablissementsComponent } from './pages/commun/liste-etablissements/liste-etablissements.component';
import {TabViewModule} from "primeng/tabview";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {PaginatorModule} from "primeng/paginator";
import { CreateAndUpdateRegionComponent } from './pages/commun/create-and-update-region/create-and-update-region.component';
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {initializer} from "../appinit";
import {APP_BASE_HREF} from "@angular/common";
import {PanelModule} from "primeng/panel";
import {InputNumberModule} from "primeng/inputnumber";
import { CreateOrUpdatePrefectureComponent } from './pages/commun/create-or-update-prefecture/create-or-update-prefecture.component';
import { CreateOrUpdateSousPrefectureComponent } from './pages/commun/create-or-update-sous-prefecture/create-or-update-sous-prefecture.component';
import { LocaliteDatasComponent } from './pages/commun/localite-datas/localite-datas.component';
import {GalleriaModule} from "primeng/galleria";
import {CardModule} from "primeng/card";
import {Accordion, AccordionModule} from "primeng/accordion";
import {CarouselModule} from "primeng/carousel";
import {ProgressBarModule} from "primeng/progressbar";
import { CheckboxModule } from 'primeng/checkbox';
import { PrimairesComponent } from './pages/primaires/primaires.component';
import { ClassementComponent } from './pages/classement/classement.component';
import { DialogModule } from 'primeng/dialog';
import { InplaceModule } from 'primeng/inplace';

@NgModule({
  declarations: [
    AppComponent,
    PrimaireComponent,
    CollegeComponent,
    LyceeComponent,
    SuperieurComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ProfessionnelComponent,
    SearchQueryComponent,
    DetailEtablissementComponent,
    DetailLocaliteComponent,
    ListeEtablissementsComponent,
    CreateAndUpdateRegionComponent,
    CreateOrUpdatePrefectureComponent,
    CreateOrUpdateSousPrefectureComponent,
    LocaliteDatasComponent,
    PrimairesComponent,
    ClassementComponent
  ],
  imports: [
    TreeModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ButtonModule,
    MegaMenuModule,
    MenubarModule,
    InputTextModule,
    DialogModule,
    InplaceModule,
    FieldsetModule,
    ToggleButtonModule,
    TableModule,
    BrowserAnimationsModule,
    DataViewModule,
    FormsModule,
    SelectButtonModule,
    MultiSelectModule,
    AutoCompleteModule,
    TabViewModule,
    MessagesModule,
    MessageModule,
    PaginatorModule,
    KeycloakAngularModule,
    PanelModule,
    ReactiveFormsModule,
    InputNumberModule,
    GalleriaModule,
    CardModule,
    AccordionModule,
    CarouselModule,
    ProgressBarModule,
    CheckboxModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: APP_BASE_HREF, useValue: window['base-href']},
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
