<br/>
<a routerLinkActive="active" (click)="goBackUrl()">
  <i class="pi pi-arrow-circle-left"> <span class="p-text-uppercase a-with-text-color"> Retour</span></i>
</a>
<div style="height: 10px"></div>
<div class="p-grid detail-search div-cadre p-m-0">
  <div class="p-text-left h-auto" style="width:670px">
    <div style="height: 10px"></div>
    <span class="p-p-0 p-text-uppercase detail-etablissement-nom-text-bold">
        {{etablissement?.nom}}
    </span>
    <div style="height: 10px"></div>
    <div class="grid-line-separator p-p-0 p-m-0"></div>
    <div style="height: 10px"></div>
    <span class="p-tag p-m-1"> {{etablissement?.statut}}</span>
    <span class="p-tag p-m-1 p-text-uppercase"> {{etablissement?.effectif}} élèves </span>
    <div style="height: 10px"></div>
    <button class="p-m-1 p-button-raised p-button-rounded search-text-bold"
            type="button" pButton pRipple label="Contacter l'établissement"></button>
    <button class="p-m-1 p-button-raised p-button-rounded search-text-bold"
            [disabled]="siteWebDisabled ? 'disabled': null"
            type="button" pButton pRipple label="Site web" (click)="redirectToWebSite()"></button>
    <br/>
    <p-message *ngIf="warningMsg" severity="error" text="Cet établissement n'a pas de site internet"></p-message>
  </div>
  <div class="p-text-left">
    <div style="height: 10px"></div>
    <span class="p-m-1 search-text-bold">Coordonnées</span>
    <div style="height: 10px"></div>
    <div class="coordonnes-separator p-p-0 p-m-0"></div>
    <div style="height: 10px"></div>
    <span class="p-m-1">
      <i class="pi pi-home"></i> {{etablissement?.adresse?.district}}
      - {{etablissement?.sousPrefecture?.nom}}
    </span>
    <br/>
    <span class="p-m-4">
      {{etablissement?.sousPrefecture?.prefecture?.nom}}
    </span>
    <br/>
    <span class="p-m-4">
      {{etablissement?.sousPrefecture?.prefecture?.regionAdministrative?.nom}}
    </span>
    <br/>
    <a href="tel:{{etablissement?.adresse?.telephone}}">
    <span class="p-m-1">
      <i class="pi pi-mobile"></i>
      {{etablissement?.adresse?.telephone}}
    </span></a>
  </div>
</div>
<div style="height: 20px">
  <ul class="icons" style="text-align: center;">
    <li *ngIf="!siteWebDisabled"><a href="{{etablissement?.siteWeb}}" target="_blank"
                                    class="icon solid fas fa-globe"><span class="label">Site internet</span></a></li>
    <li *ngFor="let reseauSocial of etablissement?.reseauxSociaux">
      <a *ngIf="reseauSocial.nom === 'Facebook'" href="{{reseauSocial.lien}}" target="_blank"
         class="icon brands fa-facebook"><span class="label">Facebook</span></a>
      <a *ngIf="reseauSocial.nom === 'Linkedin'" href="{{reseauSocial.lien}}" target="_blank"
         class="icon brands fa-linkedin"><span class="label">Facebook</span></a>
      <a *ngIf="reseauSocial.nom === 'Twitter'" href="{{reseauSocial.lien}}" target="_blank"
         class="icon brands fa-twitter"><span class="label">Twitter</span></a>
      <a *ngIf="reseauSocial.nom === 'Youtube'" href="{{reseauSocial.lien}}" target="_blank"
         class="icon brands fa-youtube"><span class="label">Twitter</span></a>
    </li>
    <li><a href="tel:{{phoneNumber}}" class="icon solid fa-phone"><span class="label">Téléphone</span></a></li>
    <li><a href="https://api.whatsapp.com/send?phone={{phoneNumber}}" class="icon brands fa-whatsapp"><span
      class="label">WhatsApp</span></a></li>
    <li><a href="mailto:{{etablissement?.adresse?.email}}" class="icon solid fa-envelope"><span
      class="label">Email</span></a></li>
  </ul>
</div>
<div *ngIf="infosPersonnel">
  <div style="height: 20px"></div>
  <div class="line-separator"></div>
  <br/>
  <div class="p-grid detail-search div-cadre p-m-0">
    <div class="p-text-left h-auto">
      <div class="card flex justify-content-center">
        <br/>
        <p-fieldset legend="Résultats {{ previousUrl == '/primaire' || previousUrl == '/college' || previousUrl == '/lycee' ?
                                  (previousUrl == '/primaire' ? 'C.E.E' : (previousUrl == '/college' ? 'B.E.P.C' : 'BAC')) : '' }}
          " [toggleable]="true">
          <table>
            <tr>
              <td>
                Année 2024
                <ul style="background-color: #F5F5F5;">
                  <li *ngIf="!hasResultatsN">Nous n'avons pas ces données.</li>
                  <li *ngIf="hasResultatsN">Nombre de candidats : {{ etablissement?.statistiques[0]?.nombreCandidats }}</li>
                  <li *ngIf="hasResultatsN">Nombre d'admis : {{ etablissement?.statistiques[0]?.nombreAdmis }}</li>
                  <li *ngIf="hasResultatsN">Taux de réussite : {{ etablissement?.statistiques[0]?.pourcentageReussite }}%</li>
                  <br/>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Année 2023
                <ul style="background-color: #F5F5F5;">
                  <br/>
                  <li>Nous n'avons pas ces données.</li>
                  <br/>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Année 2022
                <ul style="background-color: #F5F5F5;">
                  <br/>
                  <li>Nous n'avons pas ces données.</li>
                  <br/>
                </ul>
              </td>
            </tr>
          </table>
        </p-fieldset>
        <br/>
      </div>
    </div>
    <div class="p-text-left h-auto">
      <div class="card flex justify-content-center">
        <br/>
        <p-fieldset legend="Infos {{fondateur?.statut}}" [toggleable]="true">
          <table>
            <tr>
              <td>{{fondateur?.statut}} :</td>
              <td>{{fondateur?.nom}}&nbsp;{{fondateur?.prenom}}</td>
            </tr>
            <tr>
              <td>Nationalité :</td>
              <td>{{fondateur?.nationalites}}</td>
            </tr>
            <tr>
              <td>Profession :</td>
              <td>{{fondateur?.profession}}</td>
            </tr>
            <tr>
              <td>Télephone :</td>
              <td>{{fondateur?.adresse?.telephoneDeContact}}</td>
            </tr>
            <tr>
              <td colspan="2">
                <div style="height: 5px"></div>
              </td>
            </tr>
            <tr>
              <td colspan="2"><img src="{{fondateur?.profilPhoto}}" class="responsive-image"></td>
            </tr>
          </table>
        </p-fieldset>
        <br/>
      </div>
    </div>
    <div class="p-text-left">
      <div class="card flex justify-content-center">
        <br/>
        <p-fieldset legend="Infos {{directeur?.statut}}" [toggleable]="true">
          <table>
            <tr>
              <td>{{directeur?.statut}} :</td>
              <td>{{directeur?.nom}}&nbsp;{{directeur?.prenom}}</td>
            </tr>
            <tr>
              <td>Nationalité :</td>
              <td>{{directeur?.nationalites}}</td>
            </tr>
            <tr>
              <td>Profession :</td>
              <td>{{directeur?.profession}}</td>
            </tr>
            <tr>
              <td>Télephone :</td>
              <td>{{directeur?.adresse?.telephoneDeContact}}</td>
            </tr>
            <tr>
              <td colspan="2">
                <div style="height: 5px"></div>
              </td>
            </tr>
            <tr>
              <td colspan="2"><img src="{{directeur?.profilPhoto}}" class="responsive-image"></td>
            </tr>
          </table>
        </p-fieldset>
        <br/>
      </div>
    </div>
  </div>
  <div style="height: 20px"></div>
</div>
<div class="card">
  <p-tabView class="p-p-2">
    <p-tabPanel header="Multimédia" [selected]="true">
      <div *ngIf="!galeriePhotosExist">
        Nous ne disposons pas d'images de cet établissement.
        Si vous disposez des coordonnées d'un responsable, nous contacter via cet email.
        <br/>projet.letudiant.guinee&#64;gmail.com
      </div>
      <div class="card" *ngIf="galeriePhotosExist">
        <div class="content-section implementation">
          <div class="card">
            <h5>Toute la gallerie</h5>
            <div *ngIf="images" class="p-grid" style="max-width: 400px;">
              <div *ngFor="let image of images; let index = index;" class="p-col-3" key="index">
                <img [src]="image.thumbnailImageSrc" [alt]="image.alt" style="cursor: pointer"
                     (click)="imageClick(index)"/>
              </div>
            </div>
            <p-galleria [(value)]="images" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
                        [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}"
                        [numVisible]="7"
                        [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false"
                        [baseZIndex]="100000">
              <ng-template pTemplate="item" let-item>
                <img [src]="item.previewImageSrc" style="width: 100%; display: block;"/>
              </ng-template>
              <ng-template pTemplate="thumbnail" let-item>
                <div class="p-grid p-nogutter p-justify-center">
                  <img [src]="item.thumbnailImageSrc" style="display: block;"/>
                </div>
              </ng-template>
            </p-galleria>
          </div>
          <div class="card">
            <button pButton type="button" icon="pi pi-external-link"
                    label="Affichage en plein écran" (click)="displayBasic2 = true"></button>
            <p-galleria [(value)]="images" [(visible)]="displayBasic2" [responsiveOptions]="responsiveOptions"
                        [containerStyle]="{'max-width': '850px'}" [numVisible]="7"
                        [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false"
                        [baseZIndex]="100000">
              <ng-template pTemplate="item" let-item>
                <img [src]="item.previewImageSrc" style="width: 100%; display: block;"/>
              </ng-template>
              <ng-template pTemplate="thumbnail" let-item>
                <div class="p-grid p-nogutter p-justify-center">
                  <img [src]="item.thumbnailImageSrc" style="display: block;"/>
                </div>
              </ng-template>
            </p-galleria>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Mot du responsable">
      <span *ngIf="!etablissement?.motDuResponsable || etablissement.motDuResponsable.trim() === ''">
        Aucun contact n'a été établi avec cet établissement. Si vous disposez des coordonnées d'un responsable,
        veuillez nous contacter via cet email : projet.letudiant.guinee&#64;gmail.com
      </span>
      <div [innerHTML]="htmlTexte"></div>
    </p-tabPanel>
    <p-tabPanel header="H. d'ouverture">
      Nous ne disposons pas de cette information pour cet établissement.
      Si vous disposez des coordonnées d'un responsable, nous contacter via cet email. projet.letudiant.guinee&#64;gmail.com<br/>

      <table>
        <tr>
          <td>Lundi</td>
          <td>07H00 - 12H00</td>
        </tr>
        <tr>
          <td>Mardi</td>
          <td>07H00 - 12H00</td>
        </tr>
        <tr>
          <td>Mercredi</td>
          <td>07H00 - 12H00</td>
        </tr>
        <tr>
          <td>Jeudi</td>
          <td>07H00 - 12H00</td>
        </tr>
        <tr>
          <td>Vendredi</td>
          <td>07H00 - 12H00</td>
        </tr>
        <tr>
          <td>Samedi</td>
          <td>07H00 - 12H00</td>
        </tr>
      </table>
    </p-tabPanel>
    <p-tabPanel header="Formations">
      <span class="title-text-bold">Langues enseignées</span>
      <div style="height: 5px"></div>
      <div class="grid-line-separator p-p-0 p-m-0"></div>
      <div *ngIf="hasLangues">
        <ul>
          <li *ngFor="let langue of etablissement?.langues">
            {{ langue?.libelle }}
          </li>
        </ul>
      </div>
      <div style="height: 10px"></div>
      <span class="title-text-bold">Effectifs par niveau</span>
      <div style="height: 5px"></div>
      <div class="grid-line-separator p-p-0 p-m-0"></div>
      <div style="height: 10px"></div>
      <ul>
        <li *ngFor="let niveau of etablissement?.niveaux">
          {{ niveau?.libelle }}
        </li>
      </ul>
    </p-tabPanel>
  </p-tabView>
</div>
